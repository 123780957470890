import React, { useEffect, useState } from "react";
import {
  RiCheckboxFill,
  RiEyeCloseFill,
  RiListCheck,
  RiUploadCloud2Line,
} from "react-icons/ri";
import { ImEye } from "react-icons/im";
import CryptoJS from "crypto-js";

import {
  useGetApplicationQuery,
  useGetCertificateFeeQuery,
  useUpdateApplicationMutation,
} from "../../store/slices/apis/applicationApi";
import { IMAGE_BASE_URL } from "../../Utils/Common";
import {
  useGetCollegesQuery,
  useGetCoursesQuery,
  useGetDegreeModesQuery,
  useGetDegreeTypesQuery,
  useGetDocumentsQuery,
  useGetGradesQuery,
  useGetSubjectsQuery,
  useLazyGetDegreeModesByCourseQuery,
  useLazyGetDegreeTypesByCourseQuery,
  useLazyGetSubjectsByCourseQuery,
} from "../../store/slices/apis/masterApi";
import ImageModel from "../../Components/Common/ImageModel";
import { toast } from "react-toastify";
import Loader from "../../Components/Utils/Loader";
import { PaymentStatus } from "../../Utils/Status";
import { useNavigate } from "react-router-dom";
import moment from "moment";

export const CertificateReviewPayment = ({
  data: application,
  certificateId,
  setTab,
}) => {
  const [isChecked, setIsChecked] = useState(false);

  const {
    data: documents = [],
    isLoading: isGetDocumentsLoading,
    isFetching: isGetDocumentsFetching,
    isSuccess: isGetDocumentsSuccess,
  } = useGetDocumentsQuery(certificateId);
  const {
    data: fee = [],
    isLoading: isGetFeeLoading,
    isFetching: isGetFeeFetching,
    isSuccess: isGetFeeSuccess,
  } = useGetCertificateFeeQuery(application?.id);

  const handleCheckBoxChange = () => {
    const checkboxes = document.querySelectorAll('input[type="checkbox"]');
    const allChecked = Array.from(checkboxes).every(
      (checkbox) => checkbox.checked
    );
    setIsChecked(allChecked);
  };
  const [isUploaded, setIsUploaded] = useState(true); // Assuming it's initially true

  const [isLoading, setLoading] = useState(false);
  const navigate = useNavigate();

  const [showImageModel, setShowImageModel] = useState(null);
  const [paymentString, setPaymentString] = useState("");
  const [updateApplication] = useUpdateApplicationMutation();
  const {
    data: colleges = [],
    isLoading: isLoadingGetColleges,
    isSuccess: isSuccessGetColleges,
    isError: isErrorGetColleges,
  } = useGetCollegesQuery();
  const {
    data: grades = [],
    isLoading: isLoadingGetGrades,
    isSuccess: isSuccessGetGrades,
    isError: isErrorGetGrades,
  } = useGetGradesQuery();
  const {
    data: courses = [],
    isLoading: isLoadingGetCourses,
    isSuccess: isSuccessGetCourses,
    isError: isErrorGetCourses,
  } = useGetCoursesQuery();

  const [
    getDegreeTypesByCourse,
    {
      data: degreeTypes = [],
      isFetching: isFetchingGetDegreeTypes,
      isLoading: isLoadingGetDegreeTypes,
      isSuccess: isSuccessGetDegreeTypes,
      isError: isErrorGetDegreeTypes,
    },
  ] = useLazyGetDegreeTypesByCourseQuery();

  const [
    getDegreeModesByCourse,
    {
      data: degreeModes = [],
      isFetching: isFetchingGetDegreeModes,
      isLoading: isLoadingGetDegreeModes,
      isSuccess: isSuccessGetDegreeModes,
      isError: isErrorGetDegreeModes,
    },
  ] = useLazyGetDegreeModesByCourseQuery();

  const [
    getSubjectsByCourse,
    {
      data: subjects = [],
      isFetching: isFetchingGetSubjects,
      isLoading: isLoadingGetSubjects,
      isSuccess: isSuccessGetSubjects,
      isError: isErrorGetSubjects,
    },
  ] = useLazyGetSubjectsByCourseQuery();

  useEffect(() => {
    // Check if "Not Uploaded" exists in the page's text content
    if (document.body.innerText.includes("Not Uploaded")) {
      setIsUploaded(false);
    }

    getDegreeTypesByCourse(application?.course_id);
    getDegreeModesByCourse(application?.course_id);
    getSubjectsByCourse(application?.course_id);
  }, []);

  const isInvalid =
    application?.course_id !== 40 &&
    (!application?.grade_id || !application?.exam_roll_no);

  // const handleSubmit = async () => {
  //   try {
  //     setLoading(true);
  //     const result = await updateApplication({
  //       id: application.id,
  //       application_status: 1,
  //     }).unwrap();
  //     if (result.status) {
  //       toast("Saving Successful");
  //       navigate("/applied-certificates");
  //     } else {
  //       toast(result.message || "");
  //     }
  //   } catch (err) {
  //     toast(err?.data?.message || "");
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Check if the form data is valid before proceeding
    if (isInvalid) {
      alert(
        "Error: Please ensure that both Grade and Roll No fields are filled out."
      ); // Display an alert
      return;
    }

    console.log(subjects);
    if (
      ((subjects.length > 0 &&
        [1, 3, 4, 6].includes(application.degree_type_id)) ||
        (subjects.length > 0 && degreeTypes.length == 0) ||
        application.course_id == 40) &&
      !application.subject_id
    ) {
      alert("Error: Please select a Subject/Speciality.");
      return;
    }

    if (degreeTypes.length > 0 && !application.degree_type_id) {
      alert("Error: Please select a Major/General field.");
      return;
    }
    if (degreeModes.length > 0 && !application.degree_mode_id) {
      alert("Error: Please select a Regular/Distance field.");
      return;
    }
    // Perform the form submission here if everything is valid
    // ...

    const form = document.getElementById("payment");
    form.submit();
  };

  const [selectedFee, setSelectedFee] = useState();

  const handleFeeChange = (event) => {
    setSelectedFee(event.target.value);
  };

  useEffect(() => {
    const bifergator = "GENERAL";
    const payment_type =
      selectedFee == "850.00" ? "Certificate" : "Certificate and Convocation";

    const uniqueTimeStampInSeconds = moment().unix();
    const gatewayUniqueId = `${application?.id}_${uniqueTimeStampInSeconds}`;

    const str = `DIBRUGARHU|${gatewayUniqueId}|NA|${selectedFee}|NA|NA|NA|INR|NA|R|dibrugarhu|NA|NA|F|${application.name}|${application.phone_number}|${bifergator}|${application.course_id}|NA|${payment_type}|NA|https://convocationapi.dibru.work/gateway/response`;

    // $checksum = hash_hmac('sha256',$str,'EVPxLxSVgNE7', false);
    // $checksum = strtoupper($checksum);
    // $message=$str.'|'.$checksum;

    const secretKey = "EVPxLxSVgNE7";

    // Calculate the HMAC SHA-256 checksum
    const hmac = CryptoJS.HmacSHA256(str, secretKey);
    const checksum = hmac.toString(CryptoJS.enc.Hex).toUpperCase();
    // const checksum = hmac.toUpperCase();

    // Combine the original string and checksum
    const message = str + "|" + checksum;
    setPaymentString(message);
  }, [selectedFee, application]);

  // Now 'message' contains the same result as in the PHP code

  return (
    <>
      {isLoading && <Loader />}
      {showImageModel && (
        <ImageModel
          url={showImageModel}
          onClose={() => setShowImageModel(null)}
        />
      )}
      <div className='w-full p-10 space-y-8 bg-white shadow-2xl'>
        <div className=''>
          <div className='mb-2 text-lg font-bold'>
            Status: <span className='text-red-600'>Payment Pending </span>
          </div>
          <div className='flex flex-wrap'>
            <div className='w-2/6 space-y-2 font-bold'>
              <div>Name of the Candidate (English):</div>
              <div>Name of the Candidate (Assamese):</div>
            </div>
            <div className='w-4/6'>
              <div className='relative flex'>
                <div className='w-2/3 space-y-2'>
                  <div>{application?.name}</div>
                  <div className='font-Banikanta'>
                    {application?.assamese_name}
                  </div>

                  {application?.assamese_name?.length == 0 && (
                    <div>
                      <RiCheckboxFill className='inline' /> I don't know how to
                      write my name in Assamese. Therefore, I authorize the
                      Certificate Branch, DU to convert my name to Assamese on
                      my behalf. I will have No Objection on the spelling of my
                      name in Assamese converted by the Certificate Branch, DU.
                    </div>
                  )}
                </div>
                <div className='absolute right-0 w-1/3 space-y-2'>
                  {application?.applicant_photo && (
                    <img
                      className='mt-auto ml-auto h-44'
                      src={`${IMAGE_BASE_URL}${application?.applicant_photo}`}
                      alt='ProfilePhoto'
                    />
                  )}
                  {!application?.applicant_photo && (
                    <span className='float-right w-64 px-4 py-2 text-gray-700 bg-gray-100 rounded'>
                      Photo Not Uploaded
                    </span>
                  )}
                  {application?.signature && (
                    <img
                      className='h-10 mt-auto ml-auto w-44'
                      src={`${IMAGE_BASE_URL}${application?.signature}`}
                      alt='Signature'
                    />
                  )}
                  {!application?.signature && (
                    <span className='float-right w-64 px-4 py-2 mt-auto ml-auto text-gray-700 bg-gray-100 rounded'>
                      Signature Not Uploaded
                    </span>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className='flex flex-wrap space-y-2'>
            <div className='w-2/6 font-bold'>Father's Name:</div>
            <div className='w-4/6'>{application?.father_name}</div>
          </div>
          <div className='flex flex-wrap space-y-2'>
            <div className='w-2/6 font-bold'>
              Registration No of Dibrugarh University:
            </div>
            <div className='w-4/6'>{application?.registration_no}</div>
          </div>
          <div className='flex flex-wrap space-y-2'>
            <div className='w-2/6 font-bold'>Degree applied for :</div>
            {isSuccessGetCourses && (
              <div className='w-4/6'>
                {courses?.find((course) => course.id === application?.course_id)
                  ?.name || ""}
              </div>
            )}
          </div>
          {application?.degree_type_id > 0 && (
            <div className='flex flex-wrap space-y-2'>
              <div className='w-2/6 font-bold'>
                Honours/Major/Distinction/General :
              </div>
              {isSuccessGetDegreeTypes && (
                <div className='w-4/6'>
                  {degreeTypes?.find(
                    (type) => type.id === application?.degree_type_id
                  )?.name || ""}
                </div>
              )}
            </div>
          )}
          {application?.subject_id > 0 && (
            <div className='flex flex-wrap space-y-2'>
              <div className='w-2/6 font-bold'>Subject/Speciality :</div>
              {isSuccessGetSubjects && (
                <div className='w-4/6'>
                  {subjects?.find(
                    (subject) => subject.id === application?.subject_id
                  )?.name || ""}
                </div>
              )}
            </div>
          )}
          {application?.degree_mode_id > 0 && (
            <div className='flex flex-wrap space-y-2'>
              <div className='w-2/6 font-bold'> Mode :</div>
              {isSuccessGetDegreeModes && (
                <div className='w-4/6'>
                  {degreeModes?.find(
                    (mode) => mode.id === application?.degree_mode_id
                  )?.name || ""}
                </div>
              )}
            </div>
          )}
          {application?.phd_notification_no?.length > 0 &&
            application?.course_id === 40 && (
              <div className='flex flex-wrap space-y-2'>
                <div className='w-2/6 font-bold'>
                  {" "}
                  Notification No. and Date :
                </div>

                <div className='w-4/6'>{application.phd_notification_no}</div>
              </div>
            )}
          {/* <div className="flex flex-wrap space-y-2">
            <div className="w-2/6 font-bold">Year of qualifying :</div>
            <div className="w-4/6">{application?.year_of_qualify}</div>
          </div> */}
          {application?.course_id != 40 && (
            <>
              <div className='flex flex-wrap space-y-2'>
                <div className='w-2/6 font-bold'>Roll & No :</div>
                <div className='w-4/6'>
                  {application?.exam_roll_no ? (
                    application?.exam_roll_no
                  ) : (
                    <>
                      {application?.exam_roll} - {application?.exam_no}
                    </>
                  )}
                </div>
              </div>

              <div className='flex flex-wrap space-y-2'>
                <div className='w-2/6 font-bold'>Class/Division/Grade :</div>
                {isSuccessGetGrades && (
                  <div className='w-4/6'>
                    {grades?.find((grade) => grade.id === application?.grade_id)
                      ?.name || ""}
                  </div>
                )}
              </div>
            </>
          )}
          <div className='flex flex-wrap space-y-2'>
            <div className='w-2/6 font-bold'>
              Final Semester/Year examination held in (Month/Year) :
            </div>
            <div className='w-4/6'>
              {moment(application?.final_sem).format("MM/YYYY")}
            </div>
          </div>
          {/* <div className="flex flex-wrap space-y-2">
            <div className="w-2/6 font-bold">Year of Qualifying :</div>
            <div className="w-4/6">{application?.year_of_qualify}</div>
          </div> */}
          <div className='flex flex-wrap space-y-2'>
            <div className='w-2/6 font-bold'>Date of passing :</div>
            <div className='w-4/6'>
              {moment(application?.date_of_passing).format("DD/MM/YYYY")}
            </div>
          </div>
          <div className='flex flex-wrap space-y-2'>
            <div className='w-2/6 font-bold'>
              College/Department/Institute from where qualified :
            </div>
            {isSuccessGetColleges && (
              <div className='w-4/6'>
                {colleges?.find(
                  (college) => college.id === application?.college_id
                )?.name || ""}
              </div>
            )}
          </div>
          <div className='flex flex-wrap space-y-2'>
            <div className='w-2/6 font-bold'>ABC ID :</div>
            <div className='w-4/6'>{application?.abc_id}</div>
          </div>{" "}
          <div className='flex flex-wrap space-y-2'>
            <div className='w-2/6 font-bold'>Mobile No :</div>
            <div className='w-4/6'>{application?.phone_number}</div>
          </div>
          <div className='flex flex-wrap space-y-2'>
            <div className='w-2/6 font-bold'>Email :</div>
            <div className='w-4/6'>{application?.email}</div>
          </div>
          <div className='flex flex-wrap space-y-2'>
            <div className='w-2/6 font-bold'>Address for Communication:</div>
            <div className='w-4/6'>
              <p>
                Village/Town: {application?.village}, House No./Street No.:
                {application?.house_no}, P.O: {application?.postal_po},
                District:
                {application?.district}, State: {application?.state}, Pin:
                {application?.pincode}
              </p>
            </div>
          </div>
          {documents &&
            documents
              ?.filter((d) => {
                if (application.course_id == 40) {
                  return d.id == 1;
                } else {
                  return true;
                }
              })
              ?.map((document) => {
                const exists = application?.documents?.find(
                  (doc) => doc.document_id === document.id
                );
                return (
                  <div key={document.id} className='flex flex-wrap space-y-2'>
                    <div className='w-2/6 font-bold'>
                      {document.name || ""} :
                    </div>
                    {exists?.file ? (
                      <span className='px-4 py-2 text-green-700 bg-green-100 rounded'>
                        Uploaded
                      </span>
                    ) : (
                      <span className='px-4 py-2 text-gray-700 bg-gray-100 rounded'>
                        Not Uploaded
                      </span>
                    )}
                    <button
                      className='px-3 py-2 text-sm font-bold text-blue-600 uppercase rounded hover:bg-blue-600 hover:text-white'
                      type='button'
                      onClick={() => {
                        setShowImageModel(exists?.file || null);
                      }}
                    >
                      {exists?.file ? "View" : ""}
                      <ImEye className='inline-block ml-1' />
                    </button>
                  </div>
                );
              })}
        </div>

        <hr className='my-6 border border-gray-400 border-opacity-50' />

        <div className='container mx-auto'>
          <div className='p-2'>
            <h2 className='text-xl font-bold'>UNDERTAKING</h2>
            <p className='text-lg'>I hereby declare the following,</p>

            <div className='space-y-1 text-lg'>
              <div className='space-x-2'>
                <input
                  id='checkbox1'
                  type='checkbox'
                  onChange={handleCheckBoxChange}
                />
                <label className='font-semibold' for='checkbox1'>
                  I have uploaded the correct documents during the online
                  application and ensured that the scanned images of such
                  documents are distinctly visible.
                </label>
              </div>

              <div className='space-x-2'>
                <input
                  id='checkbox2'
                  type='checkbox'
                  onChange={handleCheckBoxChange}
                />
                <label className='font-semibold' for='checkbox2'>
                  I have provided my own mobile No. and email id at the time of
                  online application.
                </label>
              </div>

              <div className='space-x-2'>
                <input
                  id='checkbox3'
                  type='checkbox'
                  onChange={handleCheckBoxChange}
                />
                <label className='font-semibold' for='checkbox3'>
                  If any of the information provided by me is found to be
                  incorrect/illegible, my application shall be liable to be
                  cancelled.
                </label>
              </div>
            </div>

            <hr className='my-6 border border-gray-400 border-opacity-50' />

            <div>
              <h2 className='text-xl font-bold'>PAYMENT INFO</h2>
              <p className='text-lg'>
                [You can select any one of the payment options available i.e
                Netbanking, Debit card (Visa, Master, Maestro, RuPay), credit
                card etc. The ATM-cum debit card option is for older versions of
                ATM cards which is now supported only by limited no. of banks.]
              </p>

              <div className='my-5 text-lg row'>
                <div className='font-semibold col'>Application form for:</div>
                <div className='col'>
                  <label className='inline-flex items-center'>
                    <input
                      type='radio'
                      className='text-blue-600 form-radio'
                      name='applicationType'
                      value='850.00'
                      checked={selectedFee == "850.00"}
                      onChange={handleFeeChange}
                    />
                    <span className='ml-2'>Certificate (Rs. 850.00)</span>
                  </label>
                </div>
                <div className='col'>
                  <label className='inline-flex items-center'>
                    <input
                      type='radio'
                      disabled={[
                        1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16,
                        17, 18, 19, 41,
                      ].includes(application.course_id)}
                      className='text-blue-600 form-radio'
                      name='applicationType'
                      value='1500.00'
                      checked={selectedFee == "1500.00"}
                      onChange={handleFeeChange}
                    />
                    <span className='ml-2'>
                      Certificate & Convocation (Rs. 1500.00)
                    </span>
                  </label>
                </div>
              </div>
            </div>

            <hr />

            <div className='flex justify-between mt-3'>
              <button
                type='button'
                className='px-4 py-2 text-white bg-green-500 rounded'
                onClick={() => {
                  setTab(3);
                }}
              >
                Previous
              </button>

              {/* <button
                className='px-4 py-2 text-white bg-blue-500 rounded disabled:opacity-50 disabled:cursor-not-allowed'
                type='submit'
                onClick={handleSubmit}
                disabled={!isChecked || !selectedFee}
              >
                Confirm and Proceed to Pay Rs. {selectedFee}
              </button> */}
              {courses?.find((course) => course.id === application?.course_id)
                ?.active == 1 && (
                <form
                  name='payment'
                  id='payment'
                  method='POST'
                  action='https://pgi.billdesk.com/pgidsk/PGIMerchantPayment'
                  onSubmit={handleSubmit}
                >
                  <input
                    type='hidden'
                    name='msg'
                    id='msg'
                    value={paymentString}
                  />

                  <input
                    type='submit'
                    disabled={!isChecked || !selectedFee || !isUploaded}
                    className='px-4 py-2 text-white bg-blue-500 rounded cursor-pointer disabled:opacity-50 disabled:cursor-not-allowed'
                    value={` Confirm and Proceed to Pay Rs. ${
                      selectedFee ?? ""
                    }`}
                  />
                </form>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CertificateReviewPayment;
